import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@emotion/react';
import CircularProgress from '@material-ui/core/CircularProgress';

// import Avatar from 'react-avatar';
import { cosmicpuma } from '@nonsequitur/component-library';

import Layout from '../../components/layout';
import ProfileArticleCard from '../../components/ProfileArticleCard';
import StatCard from '../../components/ProfileStatCard';
import { useMediaQuery, useAsyncFn } from '../../utils/hooks';
import articlesClient from '../../services/ArticlesClient';

const { DropDown } = cosmicpuma;

const ProfileTemplate = (props) => {
  const {
    className,
    classes,
    username,
  } = props;

  const [sortBy, setSortBy] = useState();
  const { breakpointS } = useTheme();
  const isSmallScreen = useMediaQuery(`(max-width: ${breakpointS})`);

  const [{
    isLoading: isLoadingArticles,
    // isError: isErrorArticles,
    data: articles,
  }] = useAsyncFn(() => articlesClient.getArticlesByUsername(username));

  const handleOnChangeSortBy = (event) => {
    event.preventDefault();
    const { value } = event.target;
    setSortBy(value);
  };

  const sortByOptions = [
    { value: 'created', label: 'Recently Created' },
    { value: 'published', label: 'Recently Published' },
    { value: 'views', label: 'Most Views' },
    { value: 'ratings', label: 'Highest Rating' },
  ];

  const totalPosts = articles ? articles.length : 0;
  const statCardData = [
    { value: 300, message: 'Total post views' },
    { value: 0, message: 'Total likes' },
    { value: totalPosts, message: 'Total posts' },
  ];

  return (
    <div className={className}>
      <Layout>
        <h1>
          @
          {username}
        </h1>
        <div className={classes.statLine}>
          {statCardData.map((stat, index) => (
            <StatCard
              value={stat.value}
              message={stat.message}
              key={`${stat.value}-${index}`} // eslint-disable-line react/no-array-index-key
            />
          ))}
        </div>
        <div className={classes.postsHeaderLine}>
          <h3>Posts</h3>
          <DropDown
            id="profile-article-sort"
            name="profile-article-sort"
            label="Sort"
            placeholder="Sort by..."
            value={sortBy}
            onChange={handleOnChangeSortBy}
            options={sortByOptions}
            className={classes.sortBy}
          />
        </div>
        {isLoadingArticles && (
          <div>
            <CircularProgress />
          </div>
        )}
        {articles && (articles.map((article) => (
          <ProfileArticleCard
            key={article.id}
            article={article}
            isSmallScreen={isSmallScreen}
            className={classes.card}
          />
        )))}
      </Layout>
    </div>
  );
};

ProfileTemplate.propTypes = {
  classes: PropTypes.shape({
    statLine: PropTypes.string,
    postsHeaderLine: PropTypes.string,
    sortBy: PropTypes.string,
    card: PropTypes.string,
  }),
  className: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
};

ProfileTemplate.defaultProps = {
  classes: {
    statLine: 'profile-stat-line',
    postsHeaderLine: 'profile-posts-header-line',
    sortBy: 'profile-sortby-dropdown',
    card: 'profile-card',
  },
};

export default ProfileTemplate;
