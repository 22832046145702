import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { createEditor } from 'slate';
import { Slate, Editable, withReact } from 'slate-react';

const ReadOnlyExample = (props) => {
  const { text } = props;
  const editor = useMemo(() => withReact(createEditor()), []);
  return (
    <Slate editor={editor} value={text}>
      <Editable readOnly />
    </Slate>
  );
};

ReadOnlyExample.propTypes = {
  text: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default ReadOnlyExample;
