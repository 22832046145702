import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import Avatar from 'react-avatar';
import { cosmicpuma } from '@nonsequitur/component-library';
import CircularProgress from '@material-ui/core/CircularProgress';

import Layout from '../../components/layout';
import ReadOnlyExample from '../../components/ReadOnlyEditor';
import { formatPublicationDate } from '../../utils';
import { useAsyncFn } from '../../utils/hooks';
import articlesClient from '../../services/ArticlesClient';

const { Chip, Button } = cosmicpuma;

const ReadTemplate = (props) => {
  const {
    username,
    slug,
    classes,
    className,
  } = props;

  const cuid = slug.split('-').pop();

  const [{
    isLoading: isLoadingArticle,
    // isError: isErrorArticle,
    data: article,
  }] = useAsyncFn(() => articlesClient.getArticleByUsernameAndCuid(username, cuid));

  const handleOnClickUsername = (event) => {
    event.preventDefault();
    navigate(`/${username}`);
  };

  return (
    <div className={className}>
      <Layout>
        <div className={classes.container}>
          {isLoadingArticle && (
          <div>
            <CircularProgress />
          </div>
          )}
          {article && (
          <>
            <div className={classes.authorLine}>
              <Avatar
                round
                size="32"
                name={username}
                className={classes.avatar}
                src={article.avatar || 'http://i.stack.imgur.com/Dj7eP.jpg'}
              />
              <Button
                className={classes.username}
                variant="text"
                color="none"
                onClick={handleOnClickUsername}
              >
                @
                {username}
              </Button>
              <span className={classes.publicationDate}>{formatPublicationDate(article.publicationDate)}</span>
            </div>
            <h1>{article.title}</h1>
            <p className={classes.description}>{article.description}</p>
            <div className={classes.chips}>
              <Chip variant="outlined" color="secondary">
                #
                {article.category}
              </Chip>
              {article.tags.map((tag) => (
                <Chip variant="solid" color="primary" key={tag}>
                  #
                  {tag}
                </Chip>
              ))}
            </div>
            <div className={classes.text}>
              {article.text
                ? <ReadOnlyExample text={article.text} />
                : <CircularProgress />}
            </div>
          </>
          )}
        </div>
      </Layout>
    </div>
  );
};

ReadTemplate.propTypes = {
  classes: PropTypes.shape({
    container: PropTypes.string,
    publicationDate: PropTypes.string,
    username: PropTypes.string,
    avatar: PropTypes.string,
    authorLine: PropTypes.string,
    chips: PropTypes.string,
    text: PropTypes.string,
    description: PropTypes.string,
  }),
  className: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
};

ReadTemplate.defaultProps = {
  classes: {
    container: 'read-container',
    publicationDate: 'read-publication-date',
    username: 'read-username',
    avatar: 'read-avatar',
    authorLine: 'read-author-line',
    chips: 'read-chips',
    text: 'read-text',
    description: 'read-description',
  },
};

export default ReadTemplate;
